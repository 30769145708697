import API from './utils/request';
import { isServer } from '@utils/helper';
const baseUrl = isServer() ? process.env.REACT_APP_BASE_API : '';
const lanzhuoDomain = process.env.REACT_APP_CLOUD_API

/**
 * 提交登录
 * @param {} formData
 * @returns {Promise(<object|null>)} - 返回申请结果
 */
export const login = (formData) =>
  API.post(`/auth/login`, formData).then(({ data }) => data || null);

/**
 *用户信息
 *  @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const getCurrentInfo = () =>
  API.get(`${lanzhuoDomain}/cloud-account/api/v1/user/getInfoByToken`).then(
    ({ data }) => {
      return data || null;
    }
  );

/**
 * 获取产品分类信息
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const getProList = ({ productType }) =>
  API.get(
    `${baseUrl}/services/products/api/ess-products/classify/group?productType=${productType}`
  ).then(({ data }) => data || null);

/**
 * 资源包产品列表获取
 */
export const getResoPackProd = () =>
  API.get(
    baseUrl +
      `/services/products/api/ess-products/showEssResourcePackageProduct`
  ).then(({ data }) => data || null);

/**
 * 当前工单回复数量
 */
export const getWorkMsgNum = () =>
  API.get(`${baseUrl}/services/tickets/api/mywork/msgNum`).then(
    ({ data }) => data || null
  );

/**
 * 检验当前ess申请体验状态
 */
export const checkESSExperience = () =>
  API.get(`${baseUrl}/services/products/ess/experience/enable`).then(
    ({ data }) => data || null
  );

/**
 * 退出登录
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const logout = (formData) =>
  API.post(`${baseUrl}/auth/logout`, formData).then(({ data }) => data || null);

/**
 *   获取APP分类
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 *  */
export const getAPPTypeCode = () =>
  API.get(
    `${baseUrl}/services/store/api/appShelf/app_classify/queryDictListByTypeCode`
  ).then(({ data }) => data || null);

/**
 *   获取导航栏信息
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 *  */
export const getNavigation = () =>
  API.get(
    `${baseUrl}/services/marketing/api/navigation/show/list?ownerOperator=1&businessModule=1`
  ).then(({ data }) => data || null);

/**
 *   获取首页配置相关信息
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 *  */
export const getIndexPageConfiguration = ({
  ownerOperator,
  businessModule,
  sloganType,
}) =>
  API.get(
    `${baseUrl}/services/marketing/api/slogan/show/list?ownerOperator=${ownerOperator}&businessModule=${businessModule}&sloganType=${sloganType}`
  ).then(({ data }) => data || null);

/**
 * 用户是否登录
 */
export const checkAuthenticated = (formData) =>
  API.get(`${lanzhuoDomain}/basic-auth/api/v1/user/login/check`, formData).then(
    ({ data }) => data || null
  );

/**
 * 获取平台配置
 */
export const getBaseConfig = (basicConfigType) =>
  API.get(`${lanzhuoDomain}/cloud-config/operation/basicConfig/getBasicConfig?basicConfigType=${basicConfigType}`).then(
    ({ data }) => data || null
  );
